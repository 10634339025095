let i18nWords = {
    app: {
        action: {
            find: 'Найти',
        },
        validation: {
            issue_date: {
                is_before_receiving: 'Должно быть позже даты выдачи'
            }
        },
    },
};

export { i18nWords };
