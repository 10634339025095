import * as React from 'react';
import { DateField, Labeled, FunctionField } from 'react-admin';
import { formatDateTimeSubtract3Hours } from '@app-helpers';

export default function DateFieldLocalized(props) {
    const { label, showLabel, ...rest } = props;
    return (
        <>
            {showLabel ? (
                <Labeled label={label}>
                    <DateField {...rest} />
                </Labeled>
            ) : (
                <FunctionField render={record => formatDateTimeSubtract3Hours(record.created_at)} {...rest} label={label} />
            )}
        </>
    );
}

DateFieldLocalized.defaultProps = {
    locales: 'ru-RU',
    showLabel: true,
};
