import { required, maxLength } from 'react-admin';

const createWorkshopFormValidators = {
    name: [maxLength(255)],
    address: [required(), maxLength(255)],
};

const editWorkshopFormValidators = {
    name: [maxLength(255)],
    address: [required(), maxLength(255)],
};

export { createWorkshopFormValidators, editWorkshopFormValidators };
