import dayjs from 'dayjs';
import { defaultDayjsDateTimeFormat, dayjsDateTimeFormatWithoutTz } from '@app-helpers';

export const formatMoney = (number) =>
    new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(number);

export const formatDateTime = (val) => dayjs(val).format(defaultDayjsDateTimeFormat);

//@FIXME -3 часа
export const formatDateTimeSubtract3Hours = (val) => dayjs(val).subtract(3, 'hour').format(dayjsDateTimeFormatWithoutTz);
